import "../Styles/Buttons.css";

const Buttons = ({ buttons }) => {
    return (
        <div className="frontpage-btns">
            {buttons.map(button => {

                const btnColorScheme = button.button_color;

                const bglight = btnColorScheme.background.lightmode;
                const bgdark = btnColorScheme.background.darkmode;
                const hoverlight = btnColorScheme.hover.lightmode;
                const hoverdark = btnColorScheme.hover.darkmode;
                const textlight = btnColorScheme.text.lightmode;
                const textdark = btnColorScheme.text.darkmode;

                const styles = ` 
                    .frontpage-btn-${button.text} {
                        background: ${bglight};
                    }

                    .frontpage-btn-${button.text}:hover {
                        background: ${hoverlight};
                    }

                    .frontpage-btn-${button.text} a {
                        color: ${textlight};
                    }

                    .darkmode--activated .frontpage-btn-${button.text} {
                        background: ${bgdark};
                    }

                    .darkmode--activated .frontpage-btn-${button.text}:hover {
                        background: ${hoverdark};
                    }

                    .darkmode--activated .frontpage-btn-${button.text} a {
                        color: ${textdark};
                    }
                `;

                var styleSheet = document.createElement("style")
                styleSheet.innerText = styles
                document.head.appendChild(styleSheet)

                return (
                    button.visible ?
                        (
                            <div key={button.text} className={`frontpage-btn-container frontpage-btn-${button.text}`}>
                                {
                                    button.icon_url ?
                                        (<img src={button.icon_url} alt="" />)
                                        : ""
                                }
                                <a href={button.url}>
                                    <div className="frontpage-btn">
                                        {button.text}       
                                    </div>
                                </a>
                            </div>
                        )
                        : ""
                )
            })}
        </div>
    );
}

export default Buttons;