import "../Styles/Tabs.css";

const Tabs = ({ TAB, activeTab, setActiveTab }) => {

    

    return (
        <div
            style={
                activeTab !== TAB.SCORE ?
                {}
                : {display : "none"}
            }
            className="tab-div">
            <div className="tab-div-texts">
                <span onClick={() => setActiveTab(TAB.INTRO)}>Introduction</span>
                <span onClick={() => setActiveTab(TAB.VOCAB)}>Vocabulary</span>
                <span onClick={() => setActiveTab(TAB.EX)}>Exercise</span>
            </div>
            <div 
                className={
                    activeTab === TAB.INTRO ?
                    "active-tab-indicator-div active-tab-intro"
                    : activeTab === TAB.VOCAB ?
                    "active-tab-indicator-div active-tab-vocab"
                    : "active-tab-indicator-div active-tab-ex"
                }
            />
        </div>
    );
}

export default Tabs;