import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Exercise from "./Components/Exercise";
import Introduction from "./Components/Introduction";
import LessonNav from "./Components/LessonNav";
import Score from "./Components/Score";
import Tabs from "./Components/Tabs";
import Vocabulary from "./Components/Vocabulary";
import "./Lesson.css";

const Lesson = ({ apiBaseUrl }) => {

    const { level } = useParams();

    const lessonDocsUrl = `${apiBaseUrl}/level/${level}/docs`;
    const lessonExerciseUrl = `${apiBaseUrl}/level/${level}/exercise`;

    const [docsData, setDocsData] = useState();
    const [exerciseData, setExerciseData] = useState();

    const [exSes, setExSes] = useState(1);

    const TAB = {
        INTRO : "intro",
        VOCAB : "vocab",
        EX : "exercise",
        SCORE : "score"
    }   

    const [activeTab, setActiveTab] = useState(TAB.INTRO);
    const [score, setScore] = useState(0);

    const fetchNewExercise = () => {
        setExSes(exSes+1);
        setActiveTab(TAB.EX);
        setScore(0);
    }

    useEffect(() => {
        async function fetchDocsData () {
            const res = await fetch(lessonDocsUrl);
            const docsData = await res.json();
            console.log(docsData);
            setDocsData(docsData);
        }

        async function fetchExerciseData () {
            const res = await fetch(lessonExerciseUrl);
            const exerciseData = await res.json();
            console.log(exerciseData);
            setExerciseData(exerciseData);
        }

        fetchDocsData();
        fetchExerciseData();
        // eslint-disable-next-line
    }, [exSes]);

    return (
        <div className="Lesson">
            <Tabs 
                TAB={TAB}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
            />
            <Introduction
                show={activeTab === TAB.INTRO} 
                introText={
                    docsData ?
                        docsData.intro.md_file
                        : ""
                }
            />
            <Vocabulary
                show={activeTab === TAB.VOCAB}
                vocabText={
                    docsData ?
                        docsData.vocab.md_file
                        : ""
                }
            />
            {
                activeTab !== TAB.EX ? 
                    <LessonNav
                        TAB={TAB} 
                        activeTab={activeTab} 
                        setActiveTab={setActiveTab}
                    />
                    : null
            }
            <Exercise
                show={activeTab === TAB.EX}
                exerciseData={exerciseData}
                score={score}
                setScore={setScore}
                TAB={TAB}
                setActiveTab={setActiveTab}
            />
            <Score
                show={activeTab === TAB.SCORE}
                score={score}
                exSes={exSes}
                fetchNewExercise={fetchNewExercise}
            />
        </div>
    );
}

export default Lesson;