import { marked } from "marked";
import "../Styles/Introduction.css";

const Introduction = ({ show, introText }) => {
    return (
        <div
            style={show ? {} : {display : "none"}}
            className="intro-div"
            dangerouslySetInnerHTML={{ __html: marked.parse(introText)}}
        />
    );
}

export default Introduction;