import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import Darkmode from "darkmode-js";

// Pages
import Home from "./Pages/Home/Home";
import Lesson from "./Pages/Lesson/Lesson";

import './App.css';

const App = () => {

  useEffect(() => {
    const darkmodeConfig = {
      bottom: '64px', // default: '32px'
      time: '0s', // default: '0.3s'
      mixColor: '#fff', // default: '#fff'
      backgroundColor: '#fff',  // default: '#fff'
      buttonColorDark: '#100f2c',  // default: '#100f2c'
      buttonColorLight: '#fff', // default: '#fff'
      saveInCookies: true, // default: true,
      label: '🌓', // default: ''
      autoMatchOsTheme: false // default: true
    }  

    const darkmode = new Darkmode(darkmodeConfig);
    darkmode.showWidget();
  }, []);


  const apiBaseUrl = "https://tokipona.lol/api";

  return (
    <div className="App">
      <Router>
        <Routes>

          <Route path="/lesson/:level" element={<Lesson apiBaseUrl={apiBaseUrl} />} />

          <Route path="/" element={<Home apiBaseUrl={apiBaseUrl} />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
