import { useState, useEffect } from "react";
import Buttons from "./Components/Buttons";
import Header from "./Components/Header";
import Info from "./Components/Info";
import Levels from "./Components/Levels";

const Home = ({ apiBaseUrl }) => {

    const [data, setData] = useState();

    const pageTitle = data ? data.frontpage.title : "";
    const buttons = data ? data.frontpage.buttons : [];
    const infoText = data ? data.frontpage.content : "";
    const levels = data ? data.levels : [];


    useEffect(() => {

        const apiFrontPageUrl = `${apiBaseUrl}/main`;

        async function fetchFrontPageData () {
            // try {
            //     const res = await fetch(apiFrontPageUrl);
            //     const data = await res.json();
            //     console.log(data);
            // } catch (err) {
            //     console.log(err);
            // }

            const res = await fetch(apiFrontPageUrl);
            const data = await res.json();
            console.log(data);
            setData(data);
        }

        fetchFrontPageData();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="Home">
            <Header pageTitle={pageTitle} />
            <Buttons buttons={buttons} />
            <Info infoText={infoText} />
            <Levels levels={levels} />
        </div>
    );
}

export default Home;