import { marked } from "marked";
import "../Styles/Vocabulary.css";

const Vocabulary = ({ show, vocabText }) => {
    console.log(vocabText);

    return (
        <div
            style={show ? {} : {display : "none"}}
            className="vocab-div"
            dangerouslySetInnerHTML={{ __html: marked.parse(vocabText)}}
        />
    );
}

export default Vocabulary;