import { useState, useEffect, useRef } from "react";
import "../Styles/Exercise.css";
import { ReactComponent as CloseSvg } from "../../../Assets/close.svg";
import levenshtein from "js-levenshtein";

import correct_sound from "../../../Audio/correct.mp3";
import incorrect_sound from "../../../Audio/incorrect.mp3";

const Exercise = ({
    show,
    exerciseData,
    score,
    setScore,
    TAB,
    setActiveTab
}) => {

    const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);

    const [answer, setAnswer] = useState("");

    const activeId = exerciseData ? exerciseData[activeQuestionIndex].id : 0;
    const activeQuestion = exerciseData ? exerciseData[activeQuestionIndex].question : "Question loading ...";
    const activeText = exerciseData ? exerciseData[activeQuestionIndex].text : "Text loading ...";
    const activeAnswerArr = exerciseData ? exerciseData[activeQuestionIndex].answers : [];

    const [answerIsCorrect, setAnswerIsCorrect] = useState(null);

    const [levDist, setLevDist] = useState();

    const textAreaRef = useRef();

    const handleSecondEnter = (e) => {
        if (e.key === "Enter" && answerIsCorrect !== null) {
            nextQuestion();
        }
    }

    useEffect(() => {
        window.addEventListener('keyup', handleSecondEnter);

        return () => {
            window.removeEventListener('keyup', handleSecondEnter);
        }
        
        //eslint-disable-next-line
    }, [answerIsCorrect]);

    useEffect(() => {
        const correct_audio = new Audio(correct_sound);
        const incorrect_audio = new Audio(incorrect_sound);

        if (answerIsCorrect === true) {
            correct_audio.play();
        } else if (answerIsCorrect === false) {
            incorrect_audio.play();
        }
    }, [answerIsCorrect]);

    useEffect(() => {
        textAreaRef.current.focus();
    }, [activeQuestionIndex]);

    const nextQuestion = () => {
        if (exerciseData[activeQuestionIndex+1]) {
            setActiveQuestionIndex(activeQuestionIndex+1);
            setAnswerIsCorrect(null);
            setAnswer("");
        } else {
            setActiveQuestionIndex(0);
            setAnswerIsCorrect(null);
            setAnswer("");
            setActiveTab(TAB.SCORE);
        }
    }

    const quit = () => {
        window.top.location.href = "/";
    }

    const checkAnswer = () => {
        const answerCheckArr = activeAnswerArr.map(corrAns => levenshtein(answer.toLowerCase().replace(/[\r\n]/gm, '').trim(), corrAns.toLowerCase().replace(/[\r\n]/gm, '').trim()));
        const minLevDist = Math.min(...answerCheckArr);
        console.log(minLevDist);
        if (minLevDist <= 1) {
            setAnswerIsCorrect(true);
            setLevDist(minLevDist);
            setScore(score+1);
        } else {
            setAnswerIsCorrect(false);
        }
    }

    return (
        <div
            className="exercise-div"
            style={
                show ?
                    {}
                    : { display : "none" }
            }
        >
            <div className="exercise-element header">
                <span className="active-id-display">#{activeId}</span>
                <span className="progress-count-display">{activeQuestionIndex+1}/{exerciseData ? exerciseData.length : 1}</span>
                <span onClick={quit} className="close-btn"><CloseSvg /></span>
            </div>
            <div className="exercise-element question-container">
                <span>{activeQuestion}</span>
            </div>
            <div className="exercise-element text-container">
                <span>{activeText}</span>
            </div>
            <div className="exercise-element">
                <textarea
                    ref={textAreaRef}
                    onKeyUp={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                            e.stopPropagation();
                            textAreaRef.current.blur();
                            checkAnswer();
                        }
                    }}
                    value={answer}
                    onChange={e => setAnswer(e.target.value)}
                    placeholder="Type your answer here ..."
                />
            </div>
            <div
                className="check-btn"
                onClick={checkAnswer}
                style={
                    answerIsCorrect === null ?
                        {}
                        : { display : "none" }
                }
            >
                <strong>Check</strong>
            </div>
            <div 
                className="correct-feedback-div"
                style={
                    answerIsCorrect ?
                        {}
                        : { display : "none" }
                }    
            >
                <div className="correct-feedback-text">{levDist !== 0 ? <><strong>Correct Answer: </strong>{activeAnswerArr[0]}</> : <strong>Correct Answer!</strong>}</div>
                <div onClick={nextQuestion} className="correct-next-btn">
                    <strong>Next</strong>
                </div>
            </div>
            <div 
                className="incorrect-feedback-div"
                style={
                    answerIsCorrect === false ?
                        {}
                        : { display : "none" }
                }    
            >
                <div className="incorrect-feedback-text"><strong>Correct Answer: </strong>{activeAnswerArr[0]}</div>
                <div onClick={nextQuestion} className="incorrect-next-btn">
                    <strong>Next</strong>
                </div>
            </div>
        </div>
    );
}

export default Exercise;