import "../Styles/Level.css";

const Level = ({ level, title, description }) => {

    return (
        <div className="Level">
            <strong>Level {level}: {title}</strong>
            <p>{description}</p>
        </div>
    );
}

export default Level;