import { Link } from "react-router-dom";
import "../Styles/LessonNav.css";

const LessonNav = ({ TAB, activeTab, setActiveTab }) => {

    const handleClickPrev = () => setActiveTab(TAB.INTRO);

    const handleClickNext = () => {
        if (activeTab === TAB.INTRO) {
            setActiveTab(TAB.VOCAB)
        } else if (activeTab === TAB.VOCAB) {
            setActiveTab(TAB.EX)
        }
    }

    return (
        <div className="lesson-nav">
            <div
                className="btn go-home-btn"
                style={
                    activeTab === TAB.INTRO ?
                        {}
                        : { display : "none" }
                }
            >
                <Link to="/" style={
                    {
                        textDecoration : "none",
                        color : "white",
                    }
                }
                >
                    Home
                </Link>
            </div>
            <div
                style={activeTab === TAB.VOCAB ?
                    {}
                    : { display : "none" }}
                className="btn prev-btn"
                onClick={handleClickPrev}>
                <span>Previous</span>
            </div>
            <div
                style={
                    activeTab !== TAB.SCORE ?
                        {}
                        : {display : "none"}
                }
                onClick={handleClickNext} className="btn next-btn">
                <span>Next</span>
            </div>
        </div>
    );
}

export default LessonNav;