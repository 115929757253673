import { Link } from "react-router-dom";
import "../Styles/Score.css";

const Score = ({
    show,
    score,
    fetchNewExercise
}) => {

    return (
        <div className="score-div" style={
            show ? {} : {display : "none"}
        }>
            <div className="score-div-score">
                <h1>{score}/5</h1>
                <h2>{score >= 3 ? "Very good!" : "You need more practice."}</h2>
            </div>
            <div className="score-div-btn-container">
                <div className="btn go-home-btn">
                    <Link to="/" style={{
                        textDecoration : "none",
                        color: "white"
                    }}>
                        Home
                    </Link>
                </div>
                <div
                    className="btn practice-again-btn"
                    onClick={() => {
                        fetchNewExercise();
                    }}
                >
                    Practice Again
                </div>
            </div>
        </div>
    );
}

export default Score;