import "../Styles/Info.css";

const Info = ({ infoText }) => {

    // const infoTextDefault = "This website was created by a Kid and Waris so that you can learn a language.";
    const infoTextDefault = "This is a default hard-coded text. The api isn't working properly!"

    return (
        <div className="Info">
            {infoText ? infoText : infoTextDefault}
        </div>
    );
}

export default Info;