import { Link } from "react-router-dom";
import "../Styles/Levels.css";
import Level from "./Level";

const Levels = ({ levels }) => {

    console.log(levels);

    return (
        <div className="Levels">
            <span className="levels-text">Levels</span>
            {levels.map(({
                level,
                title,
                description
            }) => (
                <Link key={level} to={`/lesson/${level}`} style={{ textDecoration : "none", color : "black" }}>
                    <Level
                        level={level}
                        title={title}
                        description={description}
                    />
                </Link>
            ))}
        </div>
    );
}

export default Levels;